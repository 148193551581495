(function() {
    'use strict';

    angular.module('ionicData', [
        'ionicAlert',
        'ionicOffline',
        'ionicRequest',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabLocalStorage',
        'uabPromiseManagement',
        'uabRegistry'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicData').service(
        'DataManagementService',
        DataManagementService
    );

    DataManagementService.$inject = [
        'DefaultVariableService',
        'EnvironmentService',
        'ErrorService',
        '$http',
        'LocalStorageService',
        'OfflineService',
        'PromiseManagementService',
        'RegistryService',
        'RequestManagementService'
    ];

    function DataManagementService(
        DefaultVariableService,
        EnvironmentService,
        ErrorService,
        $http,
        LocalStorageService,
        OfflineService,
        PromiseManagementService,
        RegistryService,
        RequestManagementService
    ) {
        var DataManagementService = this;

        var ON_RESPONSE = 'onResponse';

        DataManagementService.buildUrl = buildUrl;
        function buildUrl(options) {
            var url = RequestManagementService.buildUrl(options);

            url = EnvironmentService.get('backendUrl') + url;

            if (EnvironmentService.get('debug')) {
                var debugParam = 'XDEBUG_SESSION_START=PHPSTORM';

                url = RequestManagementService.addParameter(url, debugParam);
            }

            if (LocalStorageService.exists('token')) {
                var tokenParam = 'token=' + LocalStorageService.get('token');

                url = RequestManagementService.addParameter(url, tokenParam);
            }

            return url;
        }

        DataManagementService.request = request;
        function request(options) {
            if (PromiseManagementService.hasRequest(options)) {
                return PromiseManagementService.getRequest(options);
            } else {
                var promise = false;

                if (OfflineService.isOnline()) {
                    var httpOptions = {};

                    var url = DataManagementService.buildUrl(options);
                    if (DefaultVariableService.isString(url)) {
                        httpOptions.url = url;
                    }

                    var data = RequestManagementService.getData(options);
                    if (data) {
                        httpOptions.data = data;
                    }

                    var header = RequestManagementService.getHeader(options);
                    if (header) {
                        httpOptions.header = header;
                    }

                    httpOptions.method = RequestManagementService.getMethod(
                        options
                    );

                    promise = $http(httpOptions).then(
                        function(response) {
                            response = response.data;

                            if (response === '') {
                                response = true;
                            }

                            RegistryService.notify(
                                ON_RESPONSE,
                                response
                            );

                            return response;
                        },
                        function(response) {
                            var statusCode = DefaultVariableService.getInteger(
                                response.status
                            );
                            
                            if (statusCode !== 404 && statusCode !== 401) {
                                ErrorService.addError(response);
                            }

                            RegistryService.notify(
                                ON_RESPONSE,
                                response
                            );

                            return false;
                        }
                    );
                } else {
                    promise = OfflineService.request(options);
                }

                return PromiseManagementService.addRequest(options, promise);
            }
        }

        DataManagementService.register = register;
        function register(callback) {
            RegistryService.register(ON_RESPONSE, callback);
        }

        DataManagementService.reset = reset;
        function reset() {

        }

        DataManagementService.reset();

        return DataManagementService;
    }
})();

(function() {
    'use strict';

    angular.module('ionicData').service(
        'ListManagementService',
        ListManagementService
    );

    ListManagementService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'PromiseManagementService',
        'RequestManagementService',
        'ResponseManagementService'
    ];

    function ListManagementService(
        DataManagementService,
        DefaultVariableService,
        PromiseManagementService,
        RequestManagementService,
        ResponseManagementService
    ) {
        var ListManagementService = this;

        function ListManager(getterFunction) {
            var ListManager = this;

            ListManager.canLoadMore = true;

            ListManager.getterFunction = DefaultVariableService.get(
                getterFunction,
                false
            );

            ListManager.hasRunGetter = false;

            ListManager.list = {};

            ListManager.pageCount = {};

            ListManager.decrementPageCount = decrementPageCount;
            function decrementPageCount(request) {
                var key = ListManagementService.getKey(request);

                if (typeof ListManager.pageCount[key] === 'undefined') {
                    ListManager.pageCount[key] = 0;
                } else {
                    ListManager.pageCount[key]--;
                }
            }

            ListManager.getPageCount = getPageCount;
            function getPageCount(request) {
                var page = 0;
                if (RequestManagementService.hasPage(request)) {
                    page = RequestManagementService.getPage(request);
                } else {
                    var key = ListManagementService.getKey(request);

                    if (typeof ListManager.pageCount[key] === 'undefined') {
                        ListManager.pageCount[key] = 1;
                    }

                    page = ListManager.pageCount[key];
                }

                return page;
            }

            ListManager.incrementPageCount = incrementPageCount;
            function incrementPageCount(request) {
                var key = ListManagementService.getKey(request);

                if (typeof ListManager.pageCount[key] === 'undefined') {
                    ListManager.pageCount[key] = 1;
                } else {
                    ListManager.pageCount[key]++;
                }
            }

            ListManager.getKey = getKey;
            function getKey(request) {
                return ListManagementService.getKey(request);
            }

            ListManager.getList = function(request) {
                var key = ListManagementService.getKey(request);

                if (typeof ListManager.list[key] === 'undefined') {
                    ListManager.list[key] = [];
                }

                return ListManager.list[key];
            };

            ListManager.setList = function(request, list) {
                var key = ListManagementService.getKey(request);

                ListManager.list[key] = list;

                return ListManager.getList(request);
            };

            ListManager.create = function(request) {
                return ListManager.request(request).then(
                    function(response) {
                        return ListManager.handleCreateResponse(
                            request,
                            response
                        );
                    }
                );
            };

            ListManager.edit = function(request) {
                return ListManager.request(request).then(
                    function(response) {
                        return ListManager.handleGetResponse(
                            request,
                            response
                        );
                    }
                );
            };

            ListManager.get = function(request) {
                var id = RequestManagementService.getId(request);

                if (id === 0) {
                    return ListManager.getPage(request);
                } else {
                    return ListManagementService.get(request).then(
                        function(response) {
                            return ListManager.handleGetResponse(
                                request,
                                response
                            );
                        }
                    );
                }
            };

            ListManager.getById = function(request, id) {
                return ListManagementService.getById(
                    id,
                    ListManager.getList(request)
                );
            };

            ListManager.getDefault = function() {
                if (ListManager.getterFunction && !ListManager.hasRunGetter) {
                    return ListManager.getterFunction().then(
                        function(value) {
                            ListManager.hasRunGetter = true;

                            return value;
                        }
                    );
                } else {
                    return ListManager.success('No data in the list.');
                }
            };

            ListManager.getItems = getItems;
            function getItems(request, response) {
                if (response) {
                    var model = RequestManagementService.getResponseModel(request);

                    if (model) {
                        return response[model];
                    }
                }

                return false;
            }

            ListManager.getMore = getMore;
            function getMore(request) {
                ListManager.incrementPageCount(request);

                request = RequestManagementService.setPage(request, ListManager.getPageCount(request));

                return ListManagementService.request(
                    request
                ).then(
                    function(response) {
                        response = ResponseManagementService.getResponse(
                            response,
                            request
                        );

                        response = ListManager.parseResponse(response, request);

                        var model = RequestManagementService.getResponseModel(request);

                        var items = ListManager.getList(request);

                        response = ResponseManagementService.setModel(response, model, items);

                        return response;
                    }
                );
            }

            ListManager.getPage = function(request) {
                var pageCount = ListManager.getPageCount(request);

                request = RequestManagementService.setPage(request, pageCount);

                return ListManagementService.request(
                    request
                ).then(
                    function(response) {
                        response = ResponseManagementService.getResponse(
                            response,
                            request
                        );

                        return ListManager.parseResponse(response, request);
                    }
                );
            };

            ListManager.handleCreateResponse = function(request, response) {
                if (response) {
                    var list = ListManager.getList(request);

                    var model = RequestManagementService.getModel(request);
                    if (model) {
                        var items = response[model];

                        list = ListManagementService.addToList(items, list);

                        ListManager.setList(request, list);

                        return items;
                    }
                }

                return false;
            };

            ListManager.handleGetResponse = function(request, response) {
                return ResponseManagementService.getResponse(response, request);
            };

            ListManager.handleRemoveResponse = function(request, response) {
                if (response) {
                    var list = ListManager.getList(request);

                    var id = RequestManagementService.getId(request);

                    if (id !== 0) {
                        var item = {
                            id: id
                        };

                        list = ListManagementService.removeFromList(item, list);

                        ListManager.setList(request, list);
                    }

                    return list;
                }

                return response;
            };

            ListManager.parseResponse = parseResponse;
            function parseResponse(response, request) {
                var items = ListManager.getItems(request, response);

                if (items) {
                    var list = ListManager.getList(request);

                    list = ListManagementService.replaceInList(items, list);

                    ListManager.setList(request, list);
                }

                return response;
            }

            ListManager.remove = function(request) {
               return ListManager.request(request).then(
                    function(response) {
                        return ListManager.handleRemoveResponse(
                            request,
                            response
                        );
                    }
                );
            };

            ListManager.request = request;
            function request(request) {
                return ListManagementService.request(request);
            }

            ListManager.reset = function reset() {
                ListManager.hasRunGetter = false;

                ListManager.resetList();

                ListManager.pageCount = {};
            };

            ListManager.resetList = resetList;
            function resetList() {
                ListManager.list = {};
            }

            ListManager.update = function(request) {
                return ListManager.request(request).then(
                    function(data) {
                        if (data) {
                            var item = false;

                            var model = RequestManagementService.getModel(request);
                            if (model && DefaultVariableService.isDefined(data[model])) {
                                item = data[model];
                            }

                            var list = ListManager.getList(request);
                            if (item) {
                                list = ListManagementService.updateInList(item, list);
                                ListManager.setList(request, list);

                                if (DefaultVariableService.isArray(item) && item.length === 1) {
                                    return ListManager.getById(request, item[0].id);
                                }
                            }

                            return list;
                        }

                        return false;
                    }
                );
            };

            ListManager.updateInList = function(request, items) {
                var list = ListManager.getList(request);

                if (DefaultVariableService.isDefined(items)) {
                    list = ListManagementService.updateInList(items, list);

                    ListManager.setList(request, list);
                }

                return list;
            };
        }

        ListManager.prototype = {
            error: function error(response) {
                return ListManagementService.error(response);
            },
            success: function success(response) {
                return ListManagementService.success(response);
            }
        };

        ListManagementService.addToList = addToList;
        function addToList(items, list) {
            items = DefaultVariableService.convertToArray(items);

            var itemsLength = items.length;
            for (var i = itemsLength - 1; i > -1; i--) {
                list.unshift(items[i]);
            }

            return list;
        }

        ListManagementService.buildRequest = buildRequest;
        function buildRequest(url, params, data) {
            var request = RequestManagementService.getRequest();

            request = RequestManagementService.setUrl(request, url);

            if (DefaultVariableService.isDefined(data)) {
                request = RequestManagementService.setData(request, data);
            }

            params = DefaultVariableService.getArray(params);
            request = RequestManagementService.setParams(
                request,
                params
            );

            return request;
        }

        ListManagementService.error = error;
        function error(message) {
            return PromiseManagementService.generateError(message);
        }

        ListManagementService.get = get;
        function get(request) {
            return ListManagementService.request(request);
        }

        ListManagementService.getById = getById;
        function getById(id, list) {
            var listLength = list.length;

            for (var i = 0; i < listLength; i++) {
                if (DefaultVariableService.isDefined(list[i].id)) {
                    if (list[i].id === id) {
                        return list[i];
                    }
                }
            }

            return false;
        }

        ListManagementService.getKey = getKey;
        function getKey(request) {
            return PromiseManagementService.getKey(
                request,
                'limit,page'
            );
        }

        ListManagementService.getListManager = getListManager;
        function getListManager(getterFunction) {
            return new ListManager(getterFunction);
        }

        ListManagementService.register = register;
        function register(key, callback) {
            DataManagementService.register(key, callback);
        }

        ListManagementService.removeFromList = removeFromList;
        function removeFromList(items, list) {
            items = DefaultVariableService.convertToArray(items);

            var itemsLength = items.length;
            for (var i = 0; i < itemsLength; i++) {
                for (var j = list.length - 1; j !== -1; j--) {
                    if (items[i].id === list[j].id) {
                        list.splice(j, 1);
                    }
                }
            }

            return list;
        }

        ListManagementService.replaceInList = replaceInList;
        function replaceInList(items, list) {
            items = DefaultVariableService.convertToArray(items);

            var itemsLength = items.length;
            for (var i = 0; i < itemsLength; i++) {
                var isAdded = false;

                var listLength = list.length;
                for (var j = 0; j < listLength; j++) {
                    if (items[i].id === list[j].id) {
                        isAdded = true;

                        list[j] = items[i];
                    }
                }

                if (!isAdded) {
                    list.push(items[i]);
                }
            }

            return list;
        }

        ListManagementService.request = request;
        function request(request) {
            return DataManagementService.request(request);
        }

        ListManagementService.reset = reset;
        function reset() {

        }

        ListManagementService.success = success;
        function success(response) {
            return PromiseManagementService.generateSuccess(response);
        }

        ListManagementService.updateInList = updateInList;
        function updateInList(items, list) {
            items = DefaultVariableService.convertToArray(items);

            var itemsLength = items.length;
            for (var i = 0; i < itemsLength; i++) {
                if (DefaultVariableService.isDefined(items[i].id)) {
                    var addItem = true;

                    var listLength = list.length;
                    for (var j = 0; j < listLength; j++) {
                        if (items[i].id === list[i].id) {
                            list[j] = DefaultVariableService.mergeObjects(
                                items[i],
                                list[j]
                            );

                            addItem = false;
                        }
                    }

                    if (addItem) {
                        ListManagementService.addToList(items[i], list);
                    }
                }
            }

            return list;
        }

        ListManagementService.reset();

        return ListManagementService;
    }
})();